<template>
  <div>
    <title-bar :title="$t('navigations.skills')" />
    <div v-if="rows" class="custom-table">
        <advanced-filter
        :fields-array="filterFields"
        :action="showFilterModal"
        @clearAction="showFilterModal = false"
        @paramsChange="setAdvancedParams"
      />
      <table-actions
        :actions="tableActions"
        :addBtnText="$t('messages.addNew')"
        :addBtnLink="$helper.getAddUrl('skills')"
        :option="filterOption"
        :options="filterOptions"
        :entries="pages"
        @onSearchInput="inputChange"
        @perPageChange="perPageChange"
        @filterChange="showFilterModal = true"
        :defaultPerPage="perPage"
      />
      <table-lite
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :sortable="sortable"
        @do-search="setSort"
        @is-finished="isLoading = false"
      >
        <template v-slot:category="data">
            {{ $helper.getEnumTranslation("SKILL_CATEGORY", data.value.category, this.$i18n.locale)}}
        </template>
        <template v-slot:image="data">
          <img v-if="data.value.file && data.value.file.url" :src="data.value.file.url" class="image" />
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">
            <edit-btn v-if="canUpdate" :path="$helper.getEditUrl('skills', data.value.id)" />
            <delete-btn v-if="canDelete" @pressDelete="deleteSkill(data.value['@id'])" />
          </div>
        </template>
      </table-lite>
      <table-pagination
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>

  </div>
</template>

  <script>
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import AdvancedFilter from "@/components/Table/AdvancedFilter.vue";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  name: "Skills",
  components: {
    TableLite,
    TablePagination,
    DeleteBtn,
    TableActions,
    EditBtn,
    TitleBar,
    AdvancedFilter
  },
  mixins: [ResourceUtils],
  created() {
    this.setPermissisons()
    this.load()
  },
  data() {
    return {
      search: "",
      isLoading: false,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      showFilterModal: false,
      sortable: {
        order: "skill",
        sort: "asc",
      },
      canUpdate: false,
      canDelete: false,
      tableActions: ['search', 'perPage','advancedFilter'],
      filterFields: [
        {
          label: 'forms.category',
          name: "category",
          value: null,
          type: "enum",
          enumName: "SKILL_CATEGORY"
        },
        {
          label: 'forms.skillType',
          name: "type",
          value: null,
          type: "entityOption",
          entityType: "SKILL_TYPE"
        },
        {
          label: "forms.insertedBy",
          name: "insertedBy",
          value: null,
          type: "customSelect",
          resource: this.$Users,
          resourceType: "employee",
        },
      ],
      advancedParams: [],
      columns: [
        {
          label: this.$t("forms.image"),
          field: "image",
          sortable: false,
        },
        {
          label: this.$t("forms.skillType"),
          field: "typeName",
          sortable: true,
        },
        {
          label: this.$t("forms.skillNo"),
          field: "skillNo",
          sortable: true,
        },
        {
          label: this.$t("forms.skill"),
          field: "skill",
          sortable: true,
        },
        {
          label: this.$t("forms.skillHun"),
          field: "skillHun",
          sortable: true,
        },
        {
          label: this.$t("forms.category"),
          field: "category",
          sortable: true,
        },
        {
          label: this.$t("forms.insertedBy"),
          field: "insertedBy",
          sortable: true,
        },
        {
          label: this.$t("forms.actions"),
          field: "actions",
          sortable: false,
          width: "80px",
        },
      ],
      rows: null
    };
  },
 methods: {
    setSort(offset, limit, order, sort) {
     this.sortable.order = order;
     this.sortable.sort = sort;
     if (order === 'typeName') {
       this.sortable.order = 'type.name';
     }
     this.load();
    },
    inputChange(value) {
        this.currentPage = 1;
        this.search = value;
        this.load();
    },
    perPageChange(num) {
     this.currentPage = 1;
     this.perPage = num;
     this.load();
    },
    onChangePage(page) {
     this.currentPage = page;
     this.load();
    },
    setAdvancedParams(data) {
        this.advancedParams = data
        this.load()
    },
    load() {
        const params = {
            itemsPerPage: this.perPage,
            page: this.currentPage,
        };
        if (this.search && this.search.length) {
            params.search = this.search;
        }
        this.advancedParams.forEach(element => {
            if (element.value !== '' && element.value !== null) {
                params[element.name] = element.value
            }
        });
        params["order[" + this.sortable.order + "]"] = this.sortable.sort;
        this.$Skills.getCollection({params}, 'skill_list').then((response) => {
            this.pages = response.data["hydra:totalItems"];
            this.rows = response.data["hydra:member"];
        });
    },
   setPermissisons() {
     if(this.$helper.userCapability('CREATE', 'SKILLS')) {
       this.tableActions.push('addBtn')
     }
     this.canUpdate = this.$helper.userCapability('UPDATE', 'SKILLS')
     this.canDelete = this.$helper.userCapability('DELETE', 'SKILLS')
   },
    deleteSkill(url) {
        this.deleteByUrl(this.$Skills, url, this.$t('skill.deleted'), null, this.loadSkills, this.error);
    },
 },
};
</script>

